import { alpha, styled, useTheme } from "@mui/material/styles";
import PropTypes from "prop-types";
import * as React from "react";
// import type { ReactNode } from "react";
import {
	Dispatch,
	type ReactNode,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
// import TreeView from "@mui/lab/TreeView";
// import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";

import {
	TreeItem,
	TreeItemProps,
	treeItemClasses,
} from "@mui/x-tree-view/TreeItem";
import { TreeView } from "@mui/x-tree-view/TreeView";

// import {AutoSizer, List} from 'react-virtualized'
// import ReactDOM from "react-dom";
// import useChartOptions from "src/hooks/useChartOptions";
// import { useEffect } from "react";
// import Scrollbar from '../../../others/Scrollbar';
import { useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { flattenDeep, isArray, isEqual } from "lodash";
import {
	generatePath,
	useLocation,
	useNavigate,
	useParams,
	useSearchParams,
} from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
// import { AutoSizer, List } from 'react-virtualized'
import {
	Components,
	IndexLocationWithAlign,
	Virtuoso,
	VirtuosoHandle,
} from "react-virtuoso";
import SimpleBarReact, { type Props as ScrollbarProps } from "simplebar-react";
import type { SiteContent } from "src/@types/SiteContent";
import type { AuthUser } from "src/@types/auth";
import { ChartTypesUrl, ElectricityTypes } from "src/@types/charts";
// import CircularProgress from '@mui/material/CircularProgress';
import {
	meteringplanBaseObject,
	meteringplanBenchmark,
	meteringplanObject,
} from "src/@types/installation";
import { MagicActionTypes, type MagicButtonActions } from "src/@types/magic";
import { MagicButtonState } from "src/@types/magic";
import { SiteItem, SitesList } from "src/@types/sites";
import type { RouterLinkProps } from "src/components/dashboard/MagicButton/layout/listComponents";
import EmptyContent from "src/components/others/EmptyContent";
import SmartLoader from "src/components/others/Loaders";
import Scrollbar, { CustomScrollbar } from "src/components/others/Scrollbar";
// import { FixedSizeList } from "react-window";
import { searchLabelById } from "src/contexts/magicButtonContext";
import useAuth from "src/hooks/useAuth";
import useLocales from "src/hooks/useLocales";
import useNodesContext from "src/hooks/useNodesContext";
import useSiteContext from "src/hooks/useSiteContext";
import { PATH_DASHBOARD } from "src/routes/paths";
import benchmarkZonedDates from "src/utils/benchmarkZonedDates";
import { goToWithPeriod, isUUID } from "src/utils/links";
import { analysisUrl } from "src/utils/links";
import { findChildrenArray, findParentArray } from "src/utils/treeFinder";
import { returnChild } from "src/utils/treeFinder";

import { t } from "ttag";
import { Iconify, IconifyRef, getTreeIcon } from "../../../../utils/getIcon";
import { displayInstallation } from "../../Metadata/functions/displayInstallation";
import { NodeSelectorVirtualised } from "./NodeSelectorVirtualised";
import SearchBar from "./searchBar";
import SearchBarNodes from "./searchBarNodes";
// import { Checkbox, FormControlLabel } from "@mui/core";
// For checkboxes, see https://codesandbox.io/s/edeyu?file=/src/App.tsx
declare module "react" {
	interface CSSProperties {
		"--tree-view-color"?: string;
		"--tree-view-bg-color"?: string;
	}
}
type StyledTreeItemProps = TreeItemProps & {
	bgColor?: string;
	color?: string;
	labelIcon?: React.ElementType<typeof Iconify>;
	labelInfo?: string;
	labelText?: string;
	childrenNumber?: number;
	nodeType?: string;
	disabled?: boolean;
	className?: string;
	data?: any;
	handleClose?: void;
	openDrawer?: boolean;
	handleNodeSelect?: React.Dispatch<any>;
};

// Custom Tree style
const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
	color: theme.palette.text.primary,
	[`& .${treeItemClasses.content}`]: {
		minHeight: "36px",
		width: "auto",
		maxWidth: "100%",
		color: theme.palette.text.primary,
		py: "0",
		px: { xs: "10px", sm: "20px" },
		"&.Mui-selected, &.Mui-selected.Mui-focused": {
			backgroundColor: alpha(theme.palette.primary.main, 0.08),
			color: theme.palette.primary.main,
			fontWeight: "bold",
			[`& *`]: {
				color: theme.palette.primary.main,
			},
		},
		"&.Mui-focused, ": {
			backgroundColor: "initial",
		},
		[`& .${treeItemClasses.label}`]: {
			paddingLeft: 0,
			fontWeight: "inherit",
			color: "inherit",
		},
		[`& .${treeItemClasses.iconContainer}`]: {
			margin: 0,
			marginLeft: "2px",
			marginRight: "2px",
			paddingTop: "4px",
			width: "16px",
			color: theme.palette.text.disabled,
		},
	},
	[`& .${treeItemClasses.group}`]: {
		// marginLeft: 52,
		marginLeft: 48,
		borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
		borderTop: "none",
		[`& .${treeItemClasses.content}`]: {
			paddingLeft: 5,
		},
		[`& .${treeItemClasses.group}`]: {
			marginLeft: 44,
		},
	},
	[`&.subheader`]: {
		// position: 'sticky', // does not work
		cursor: "default",
		opacity: 1,
		fontWeight: "bold",
		[`& .Mui-disabled`]: {
			opacity: 1,
		},
		[`& .${treeItemClasses.iconContainer}`]: {
			width: 0,
		},
		[`& *`]: {
			cursor: "default",
			color: theme.palette.primary.main,
			[`&:hover`]: {
				background: "initial",
			},
		},
	},
}));

export function StyledTreeItem(props: StyledTreeItemProps) {
	const { label, nodeType, childrenNumber, ...other } = props;
	const icon = getTreeIcon(nodeType);

	const { currentLang } = useLocales();

	return (
		<StyledTreeItemRoot
			label={
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						textDecoration: "none",
						color: "text.primary",
						cursor: "pointer",
						p: 0,
					}}
				>
					{icon && (
						<Box
							color="inherit"
							sx={{
								minWidth: nodeType !== "all" ? "32px" : "0px",
								height: nodeType !== "all" ? "24px" : "0px",
							}}
						>
							{" "}
							{icon}{" "}
						</Box>
					)}
					<Typography
						variant={nodeType === "subheader" ? "overline" : "body2"}
						sx={{
							fontWeight: "inherit",
							flexGrow: 1,
							// paddingRight: 2,
						}}
					>
						{label}
					</Typography>
					<Typography variant="caption" color="text.disabled">
						{childrenNumber
							? childrenNumber.toLocaleString(currentLang.numberLocale)
							: childrenNumber}
					</Typography>
				</Box>
			}
			{...other}
		/>
	);
}

StyledTreeItem.propTypes = {
	label: PropTypes.string.isRequired,
	nodeType: PropTypes.string,
};
// Get Tree Items From Data
const GetTreeItemsFromData = (
	data: meteringplanBaseObject[] | SiteItem[],
	dataType: any,
	urlParam: string,
	getKey: (item: any) => string,
	isDisabled: (item: any) => boolean | undefined,
	hasChildrenWithUserAccess: (item: any) => boolean | undefined,
	hasDateBeginCustomer: (item: any) => boolean | undefined,
	dispatchMagic?: Dispatch<MagicButtonActions>,
	currentCharType?: ElectricityTypes,
): ReactNode[] | undefined => {
	if (data !== undefined) {
		return data.map((treeItemData) => {
			// return data.map((treeItemData:any) => {
			let children: ReactNode[] | undefined = undefined;

			if (
				(treeItemData.type === "customer" ||
					treeItemData.type === "site_group" ||
					treeItemData.type === "physical") &&
				treeItemData.children &&
				treeItemData.children.length > 0 &&
				hasChildrenWithUserAccess(treeItemData)
			) {
				children = GetTreeItemsFromData(
					treeItemData.children,
					dataType,
					urlParam,
					getKey,
					isDisabled,
					hasChildrenWithUserAccess,
					hasDateBeginCustomer,
				);
			}

			// const getAllSite = (children: any) => {
			// 	const data: any[] = [];
			// 	children.map((item: any) => {
			// 		if (isArray(item.children) && item.children.length > 0) {
			// 			data.push(getAllSite(item.children));
			// 		} else {
			// 			data.push(item);
			// 		}
			// 	});
			// 	return data;
			// };

			// const AllSiteInfo = flattenDeep(getAllSite(treeItemData as any) as any)

			// const sitecounter = AllSiteInfo.length
			// const siteSurfaceTotal = Math.round(sum(AllSiteInfo.map((SiteData) => { return (SiteData as SiteItemCustomerChild).buildingSurface }))).toLocaleString(currentLang.numberLocale)

			if (!isDisabled(treeItemData) && hasDateBeginCustomer(treeItemData)) {
				return (
					<StyledTreeItem
						key={getKey(treeItemData)}
						nodeId={getKey(treeItemData)}
						label={treeItemData.label}
						nodeType={dataType || treeItemData.type}
						childrenNumber={
							children ? returnChild(treeItemData).length : undefined
						}
						onClick={() => {
							if (
								dispatchMagic &&
								currentCharType &&
								currentCharType === ElectricityTypes.Benchmark
							)
								if (
									treeItemData.type === "virtual" ||
									treeItemData.type === "physical" ||
									treeItemData.type === "total_by_end_use" ||
									treeItemData.type === "total_by_zone"
								) {
									dispatchMagic({
										type: MagicActionTypes.List,
										payload: {
											id: "chartType",
											value: ElectricityTypes.EnergyMonth,
										},
									});
								}
						}}
						children={children}
						disabled={isDisabled(treeItemData)}
					/>
				);
			} else if (children !== undefined) {
				return children;
			} else {
				return undefined;
			}
		});
		// }
	}
};

const needVirtualSynthese = (virtual: meteringplanBaseObject[]) =>
	virtual !== undefined && virtual.length > 0;

const styledJSXSynthese = () => {
	return (
		<StyledTreeItem
			key="virtual"
			nodeId="virtual"
			label={t`Syntheses`}
			nodeType="subheader"
			disabled
			className="subheader"
		/>
	);
};
const styledJSXStyledBenchmark = (props: {
	data: SiteContent;
	dispatchMagic: Dispatch<MagicButtonActions>;
	state: MagicButtonState;
}) => {
	const { data, dispatchMagic, state } = props;
	return (
		<StyledTreeItem
			key="benchmark"
			nodeId={"default"}
			label={t`Total by measuring point by end-use`}
			nodeType="virtual"
			className="virtual"
			onClick={() => {
				let { start, end } = benchmarkZonedDates(
					data.metering_plan.map(
						(insta) =>
							({
								...insta,
								timezone: data.timezone,
							}) as unknown as meteringplanBenchmark,
					),
				);
				dispatchMagic({
					type: MagicActionTypes.updateStateAction,
					payload: {
						newState: {
							...state,
							chartType: ElectricityTypes.Benchmark,
							// installationLabel: t`Total by measuring point by end-use`,
							// mainDates: { dateRange: [start, end], shortcut: undefined },
							minDate: start,
							maxDate: end,
						},
					},
				});
			}}
		/>
	);
};
export const getVirtual = (
	data: SiteContent,
	user: AuthUser,
	forSiteInfo?: boolean,
) =>
	data.virtual.filter((insta) =>
		displayInstallation(
			insta,
			user,
			forSiteInfo ? "selectorForSiteInfo" : "selector",
		),
	);
export const getPhysical = (
	data: SiteContent,
	user: AuthUser,
	forSiteInfo?: boolean,
) =>
	data.metering_plan.filter((insta) =>
		displayInstallation(
			insta,
			user,
			forSiteInfo ? "selectorForSiteInfo" : "selector",
		),
	);
export const NeedStyledJSXStyledBenchmark = (
	physical: meteringplanObject[],
): { childLength: number; physicalLength: number } => {
	const countChildren = (obj: meteringplanObject): number => {
		if (!obj.children || obj.children.length === 0) {
			return 0;
		}
		return (
			obj.children.length +
			obj.children.reduce((acc, child) => acc + countChildren(child), 0)
		);
	};

	return {
		childLength: physical.reduce((acc, obj) => acc + countChildren(obj), 0),
		physicalLength: physical.length,
	};
};
// Build Installation Selector
const BuildInstallationSelector = (
	data: SiteContent | undefined,
	user: AuthUser,
	state: MagicButtonState,
	forSiteInfo?: boolean,
	dispatchMagic?: Dispatch<MagicButtonActions>,
) => {
	const theme = useTheme();

	let content = [];

	if (
		(!forSiteInfo || (forSiteInfo && user && user.isSmartImpulse)) &&
		data?.virtual !== undefined &&
		data?.virtual.length > 0
	) {
		const virtual = getVirtual(data, user, forSiteInfo);
		const physical = getPhysical(data, user, forSiteInfo);

		if (needVirtualSynthese(virtual)) {
			content.push(
				styledJSXSynthese(),
				data &&
					state.dashboard === "monosite" &&
					dispatchMagic &&
					NeedStyledJSXStyledBenchmark(physical).physicalLength > 1 &&
					styledJSXStyledBenchmark({ data, dispatchMagic, state }),
				GetTreeItemsFromData(
					virtual,
					"virtual",
					"installation",
					(item: meteringplanBaseObject) => item.data_id,
					(item: meteringplanBaseObject) => !item.user_access,
					(item: meteringplanBaseObject) => item.has_children_with_user_access,
					(item: meteringplanBaseObject) => item.date_begin_customer !== null,
					dispatchMagic,
					state.chartType,
				),
			);
		}
	}
	if (data?.metering_plan !== undefined && data.metering_plan.length > 0) {
		const physical = getPhysical(data, user, forSiteInfo);
		if (physical !== undefined && physical.length > 0) {
			const virtual = getVirtual(data, user, forSiteInfo);
			if (
				!needVirtualSynthese(virtual) &&
				physical.length > 1 &&
				data &&
				state.dashboard === "monosite" &&
				dispatchMagic
			) {
				content.push(
					styledJSXSynthese(),
					styledJSXStyledBenchmark({ data, dispatchMagic, state }),
				);
			}
			content.push(
				<StyledTreeItem
					key="physical"
					nodeId="physical"
					label={forSiteInfo ? t`Equipment information` : t`Measuring points`}
					nodeType="subheader"
					disabled
					className="subheader"
				/>,
				GetTreeItemsFromData(
					physical,
					"physical",
					"installation",
					(item: meteringplanBaseObject) => item.data_id,
					(item: meteringplanBaseObject) =>
						forSiteInfo
							? !item.user_access || !item.can_view_metadata
							: !item.user_access,
					(item: meteringplanBaseObject) =>
						forSiteInfo
							? item.has_children_with_user_access &&
								item.has_children_view_metadata
							: item.has_children_with_user_access,
					(item: meteringplanBaseObject) => item.date_begin_customer !== null,
					dispatchMagic,
					state.chartType,
				),
			);
		}
	}

	if (content !== undefined && content.length > 0) {
		return <>{content}</>;
	} else if (forSiteInfo) {
		return (
			<>
				<Typography
					variant="overline"
					sx={{
						color: theme.palette.primary.main,
						fontWeight: "bold",
						px: "12px",
						py: "9px",
					}}
				>
					{t`Equipment information`}
				</Typography>
				<Typography variant="body2" sx={{ pl: "30px" }}>
					{t`No measuring point`}
				</Typography>
			</>
		);
	} else {
		return (
			<EmptyContent
				id={"EmptyContent"}
				title={t`No results found`}
				sx={{ padding: 0, "& .MuiBox-root": { maxHeight: "130px" } }}
			/>
		);
	}
};

// Build Sites Selector
const BuildSitesSelector = (data: SitesList) => {
	let content = GetTreeItemsFromData(
		data,
		null,
		"node",
		(item: SiteItem) => item.uuid,
		(_item: SiteItem) => undefined,
		(_item: SiteItem) => true,
		(_item: SiteItem) => true,
	);
	if (data.length > 0) {
		content &&
			content.unshift(
				<StyledTreeItem
					key="all"
					nodeId="all"
					label={t`All my sites`}
					nodeType={"all"}
					childrenNumber={returnChild(data).length}
				/>,
			);
	}

	if (content !== undefined && content.length > 0) {
		return <>{content}</>;
	} else {
		return (
			<EmptyContent
				key={"EmptyContent"}
				title={t`No results found`}
				sx={{ padding: 0, "& .MuiBox-root": { maxHeight: "130px" } }}
			/>
		);
	}
};

type InstallationSelectorProps = {
	openDrawer?: boolean;
	handleClose?: () => void;
	forSiteInfo?: boolean;
	SiteSiteSelector?: SiteContent;
	dispatchMagic?: Dispatch<MagicButtonActions>;
	state: MagicButtonState;
};

export function InstallationSelector(props: InstallationSelectorProps) {
	const { Site } = useSiteContext();
	const [SiteSiteSelector, setSiteSiteSelector] = React.useState(Site);
	// const { state } = useMagicButtonContext();
	const { state, dispatchMagic } = props;
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	const { user } = useAuth();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const initSite = Site;
	const initialSelectedNode: string[] = [];
	const nodesWithChildren: string[] = [];

	if (SiteSiteSelector && SiteSiteSelector.metering_plan) {
		// Virtual points never have children
		const filteredInstallations = SiteSiteSelector.metering_plan.filter(
			(item) => item.children && item.children.length > 0,
		);
		filteredInstallations.forEach((item) =>
			nodesWithChildren.push(item.data_id),
		);
	}

	// Re-expand the nodes where the current site is a children
	if (SiteSiteSelector && SiteSiteSelector.metering_plan) {
		const filteredInstallations = SiteSiteSelector.metering_plan.filter(
			(item) => item.children && item.children.length > 0,
		);
		filteredInstallations.forEach((item) => {
			const children_data_id = item.children!.map((child) => child.data_id);
			if (children_data_id.includes(state.installation)) {
				initialSelectedNode.push(item.data_id);
			}
		});
	}

	const [expanded, setExpanded] = React.useState(nodesWithChildren);
	const handleToggle = (_event: any, nodeIds: any) => {
		setExpanded(nodeIds);
	};

	const handleClose = props.handleClose || (() => {});
	const opened = props.openDrawer;

	const handleExpandClick = (command?: string) => {
		if (typeof command !== "undefined" && command === "collapse") {
			setExpanded(initialSelectedNode);
		} else {
			if (typeof command !== "undefined" && command === "expand") {
				setExpanded(nodesWithChildren);
			} else {
				//Toggle
				setExpanded((oldExpanded: string[]) =>
					oldExpanded.length === 0 ? nodesWithChildren : [],
				);
			}
		}
	};

	return (
		<Box
			id="installationSelector-content"
			sx={{
				// height: 450,
				display: "flex",
				flexDirection: "column",
				flexGrow: 1,
				flexShrink: 1,
				overflow: props.forSiteInfo ? "hidden" : "auto",
				gap: 0,
				minWidth: "200px",
				maxWidth: "100%",
				...(isMobile && { width: "100%" }),
				// maxHeight: "80vh"
				// overflowY: "auto",
			}}
		>
			{!props.forSiteInfo && (
				<SearchBarNodes
					expanded={expanded}
					handleExpandClick={handleExpandClick}
					openDrawer={opened !== undefined ? opened : false}
					initSite={initSite}
					Site={SiteSiteSelector}
					setSite={setSiteSiteSelector}
				/>
			)}
			{/* <Scrollbar> */}
			<TreeView
				aria-label={state ? state.installation : undefined}
				id="installation"
				sx={{
					...(isMobile
						? { flexGrow: 1, flexShrink: 1 }
						: { maxHeight: props.forSiteInfo ? "initial" : "400px" }),
					overflow: props.forSiteInfo ? "hidden" : "auto",
					pb: "1px",
					...CustomScrollbar(),
				}}
				disabledItemsFocusable={false}
				className="scroller"
				// defaultExpanded={nodesWithChildren}
				expanded={expanded}
				onNodeToggle={handleToggle}
				selected={
					state &&
					state.dashboard === "monosite" &&
					state.chartType === ElectricityTypes.Benchmark
						? "default"
						: state.installation
							? state.installation
							: ""
				}
				onNodeSelect={(event: React.SyntheticEvent, nodeId: string) => {
					if ((event.target as HTMLElement).classList.contains("expandIcon"))
						return;

					handleClose();

					const path = {
						...location,
						pathname: location.pathname.replace(
							params.installation as string,
							nodeId,
						),
					};

					if (props.forSiteInfo) {
						navigate(
							generatePath(PATH_DASHBOARD.general.installation_info, {
								installation: nodeId,
								node: params.node,
							}),
						);
					} else {
						navigate(path);
					}

					// The magicButtonState will be updated via the modified pathname
				}}
				// onNodeFocus={(event: React.SyntheticEvent, nodeId: string) => {
				// }}
				defaultCollapseIcon={
					<IconifyRef
						name="ph:minus-circle"
						width={16}
						className="expandIcon"
					/>
				}
				defaultExpandIcon={
					<IconifyRef name="ph:plus-circle" width={16} className="expandIcon" />
				}
				defaultEndIcon={<div style={{ width: 24 }} />}
			>
				{BuildInstallationSelector(
					props.forSiteInfo && props.SiteSiteSelector
						? props.SiteSiteSelector
						: SiteSiteSelector,
					user,
					state,
					props.forSiteInfo,
					dispatchMagic,
				)}
			</TreeView>
		</Box>
	);
}

type SitesSelectorProps = {
	openDrawer?: boolean;
	handleClose?: void;
	setSelectedText: (val: string) => void;
};

export function SitesSelector(props: SitesSelectorProps) {
	const { Sites, MultiSites, nodesLoaded } = useNodesContext();
	const { Site } = useSiteContext();
	const navigate = useNavigate();
	const location = useLocation();
	const params = useParams();
	// const [searchParams] = useSearchParams();
	const [SitesSiteSelector, setSitesSiteSelector] = React.useState(Sites);

	const nodeSelected = (params.customer ? MultiSites : Site) || [];

	const [searchInput, setSearchInput] = React.useState<string>("" as string);
	const initSites = Sites;

	let initialSelectedNode: string[] = [];

	let nodesWithChildren: string[] = [];

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

	const [expanded, setExpanded] = React.useState<string[]>([]);
	const TreeViewRef = React.useRef(null);
	const selectedTree =
		nodeSelected && isArray(nodeSelected) && nodeSelected.length > 0
			? "all"
			: "uuid" in nodeSelected
				? nodeSelected.uuid
				: "";

	const [selectedExpanded, setSelectedExpanded] =
		React.useState<string>(selectedTree);

	const buildSitesTree = BuildSitesSelector(SitesSiteSelector);
	const [isLoaded, setIsLoaded] = React.useState(false);

	const handleToggle = (_event: any, nodeIds: string[]) => {
		setExpanded(nodeIds);
	};

	React.useEffect(() => {
		if (nodeSelected && !isArray(nodeSelected)) {
			if (
				(nodeSelected as SiteItem).type === "customer" ||
				(nodeSelected as SiteItem).type === "site_group" ||
				(nodeSelected as SiteContent).unique_id
			) {
				const findParent = findParentArray(
					SitesSiteSelector as SiteItem[],
					nodeSelected.uuid,
				);
				initialSelectedNode =
					findParent.length > 0 ? findParent : [nodeSelected.uuid];
				nodesWithChildren = initialSelectedNode;
			}
		}
	}, [isLoaded, nodeSelected, nodesLoaded]);

	React.useEffect(() => {
		// Scroll to top when searching
		if (!isLoaded) return;
		if (searchInput !== "") {
			document.querySelector("ul#node")?.scrollTo(0, 0);
		} else {
			setExpanded(initialSelectedNode);
		}
	}, [isLoaded && searchInput]);

	React.useEffect(() => {
		if (
			searchInput === "" &&
			isEqual(expanded, initialSelectedNode) &&
			isLoaded
		) {
			if (params.node && Site?.uuid) {
				document.getElementById(`node-${Site?.uuid}`)?.scrollIntoView();
			}
			if (
				params.customer &&
				MultiSites &&
				!isArray(MultiSites) &&
				MultiSites?.uuid
			) {
				document.getElementById(`node-${MultiSites?.uuid}`)?.scrollIntoView();
			}
		}
	}, [expanded && isLoaded]);

	React.useEffect(() => {
		if (nodesLoaded) {
			setSitesSiteSelector(Sites);
			setIsLoaded(true);
		}
	}, [nodesLoaded]);

	const handleExpandClick = (command?: string) => {
		if (typeof command !== "undefined" && command === "collapse") {
			setExpanded(initialSelectedNode);
		} else {
			(SitesSiteSelector as SiteItem[]).map((item) => {
				nodesWithChildren.push(item.uuid);
				findChildrenArray(item).map((child_uuid) =>
					nodesWithChildren.push(child_uuid),
				);
			});
			if (typeof command !== "undefined" && command === "expand") {
				setExpanded(nodesWithChildren);
			} else {
				//Toggle
				setExpanded((oldExpanded: string[]) =>
					oldExpanded.length === 0 ? nodesWithChildren : [],
				);
			}
		}
	};
	const handleClose = props.handleClose || (() => {});

	const getNextPath = (
		pathname: string,
		nodeId: string,
		nodeParams: any,
		instalParams: any,
		// customerParams: any,
	) => {
		let segments = pathname.split("/");
		segments = segments.slice(1);

		switch (segments[0]) {
			case "analysis":
				isUUID(nodeId)
					? navigate(
							location.pathname.replace(
								nodeParams + "/" + instalParams,
								nodeId + "/default",
							),
						)
					: navigate(goToWithPeriod(PATH_DASHBOARD.dashboard_customer, nodeId));
				break;
			case "dashboard":
				isUUID(nodeId)
					? navigate(
							generatePath(PATH_DASHBOARD.dashboard_with_node, {
								node: nodeId + "/default",
							}),
						)
					: navigate(goToWithPeriod(PATH_DASHBOARD.dashboard_customer, nodeId));
				break;
			case "table":
				isUUID(nodeId)
					? navigate(
							generatePath(PATH_DASHBOARD.dashboard_with_node, {
								node: nodeId + "/default",
							}),
						)
					: navigate(goToWithPeriod(PATH_DASHBOARD.dashboard_customer, nodeId));
				break;
			case "map":
				isUUID(nodeId)
					? navigate(
							generatePath(PATH_DASHBOARD.dashboard_with_node, {
								node: nodeId + "/default",
							}),
						)
					: navigate(goToWithPeriod(PATH_DASHBOARD.dashboard_customer, nodeId));
				break;
			case "alerts":
				isUUID(nodeId)
					? navigate(
							generatePath(PATH_DASHBOARD.alerts.notifications_with_node, {
								node: nodeId,
							}),
						)
					: navigate(goToWithPeriod(PATH_DASHBOARD.dashboard_customer, nodeId));
				break;
			case "info":
				isUUID(nodeId)
					? navigate(
							generatePath(PATH_DASHBOARD.general.site_info_with_node, {
								node: nodeId,
							}),
						)
					: navigate(goToWithPeriod(PATH_DASHBOARD.dashboard_customer, nodeId));
				break;
		}
	};

	return (
		<Box
			id="nodeSelector-content"
			sx={{
				// height: 450,
				display: "flex",
				flexDirection: "column",
				flexGrow: 1,
				flexShrink: 1,
				overflow: "auto",
				gap: 0,
				...(!isMobile && { minWidth: "500px" }),
				maxWidth: "100%",
				...(isMobile && { width: "100%" }),
				overflowY: "hidden",
				borderRadius: "6px",
				maxHeight: "80vh",
			}}
		>
			{!isLoaded ? (
				<Box sx={{ height: "20vh", width: "20vw" }}>
					<SmartLoader isChart={false} />
				</Box>
			) : (
				<>
					<SearchBar
						expanded={expanded}
						handleExpandClick={handleExpandClick}
						openDrawer={true}
						initSites={initSites}
						Sites={SitesSiteSelector}
						setSites={setSitesSiteSelector}
						searchInput={searchInput}
						setSearchInput={setSearchInput}
					/>
					<TreeView
						ref={TreeViewRef}
						aria-label={"Sites"}
						id="node"
						expanded={expanded}
						onNodeToggle={handleToggle}
						selected={selectedTree}
						onNodeSelect={(e: React.SyntheticEvent, nodeId: string) => {
							const selectedNode = searchLabelById(
								"uuid",
								nodeId,
								SitesSiteSelector as SiteItem[],
							);
							if (e.target instanceof HTMLParagraphElement) {
								if (nodeId === "all") {
									navigate(
										generatePath(PATH_DASHBOARD.dashboard_customer, {
											customer: nodeId,
										}),
									);
									handleClose();
								}
								if (
									selectedNode?.type === "customer" ||
									selectedNode?.type === "site_group"
								) {
									handleClose();
									if (
										(params.node && location.pathname.includes(params.node)) ||
										(params.customer &&
											location.pathname.includes(params.customer))
									) {
										getNextPath(
											location.pathname,
											nodeId,
											params.node,
											params.installation,
											// params.customer,
										);
									}
								} else if (selectedNode?.type === "site") {
									handleClose();
									if (
										(params.node && location.pathname.includes(params.node)) ||
										(params.customer &&
											location.pathname.includes(params.customer))
									) {
										// Some pages have the site selector, but without having the node in the URL
										getNextPath(
											location.pathname,
											nodeId,
											params.node,
											params.installation,
											// params.customer,
										);
									}
								}
							} else setSelectedExpanded(nodeId);
							// other case: node with children: (selectedNode.type === "node" && selectedNode.children.length)
						}}
						disabledItemsFocusable={false}
						defaultCollapseIcon={
							<IconifyRef
								name="ph:minus-circle"
								width={16}
								style={{ width: "24px", textAlign: "center" }}
								verticalAlign="middle"
								className="expandIcon"
							/>
						}
						defaultExpandIcon={
							<IconifyRef
								name="ph:plus-circle"
								width={16}
								style={{ width: "24px", textAlign: "center" }}
								verticalAlign="middle"
								className="expandIcon"
							/>
						}
						defaultEndIcon={<div style={{ width: 24 }} />}
						sx={{
							width: "100%",
							pb: "1px",
							"& .MuiTreeItem-iconContainer": {
								width: "24px !important",
								pt: "0px !important",
							},
						}}
					>
						<NodeSelectorVirtualised
							buildSitesTree={buildSitesTree}
							selectedExpanded={
								selectedExpanded ? selectedExpanded : selectedTree
							}
							expanded={expanded}
						/>
					</TreeView>
				</>
			)}
		</Box>
	);
}
