import { Box } from "@mui/material";
import { isArray } from "lodash";
import React, { useMemo } from "react";
import { Virtuoso } from "react-virtuoso";
import { CustomScrollbar } from "src/components/others/Scrollbar";
import { getIndexforSelectedTree } from "src/utils/treecomponentUtils/indexforSelectedTree";
import { t } from "ttag";

const MUIComponents = {
	Item: ({ ...props }) => {
		return (
			<Box component="div" {...props} style={{ margin: 0 }}>
				{props.children}
			</Box>
		);
	},
	Scroller: React.forwardRef(({ ...props }, ref) => {
		return (
			<Box
				ref={ref}
				sx={{
					...CustomScrollbar(),
					height: "100%",
					width: "100%",
					overflow: "auto",
				}}
				{...props}
			>
				{props.children}
			</Box>
		);
	}),
};
type NodeSelectorVirtualisedProps = {
	buildSitesTree: JSX.Element;
	selectedExpanded: string;
	expanded: string[];
};
const itemSizeinPX = 36;

export const NodeSelectorVirtualised = (
	props: NodeSelectorVirtualisedProps,
) => {
	const { buildSitesTree, selectedExpanded, expanded } = props;
	const getCurrentIndexInfo = getIndexforSelectedTree(
		buildSitesTree,
		selectedExpanded,
	);
	const getoffset = getCurrentIndexInfo.directIndex
		? getCurrentIndexInfo.directIndex * itemSizeinPX +
			getCurrentIndexInfo.parentCount * itemSizeinPX
		: getCurrentIndexInfo.parentCount * itemSizeinPX;

	const itemContentBuild = (index: number) => {
		if (
			buildSitesTree.props &&
			buildSitesTree.props.children &&
			buildSitesTree.props.children[index]
		) {
			return buildSitesTree.props.children[index];
		} else if (!buildSitesTree.props.children) {
			return buildSitesTree;
		}
	};

	const initialTopItemIndex: any = {
		index: getCurrentIndexInfo.index,
		align: "start",
		offset: getoffset,
	};
	const { itemContentNode } = useMemo(() => {
		const itemContentNode = buildSitesTree.props.children
			? buildSitesTree.props.children
			: [buildSitesTree];
		return { itemContentNode };
	}, [buildSitesTree]);

	const countChildrenRecursively = (item: any, expanded: string[]): number => {
		let childrenCount = 0;
		if (item.props && item.props.children && isArray(item.props.children)) {
			if (expanded.includes(item.key)) {
				childrenCount += item.props.children.length;
				childrenCount += item.props.children.reduce(
					(total: number, child: any) => {
						return total + countChildrenRecursively(child, expanded);
					},
					0,
				);
			}
		} else {
			childrenCount = item.props.childrenNumber || 0;
		}
		return childrenCount;
	};

	const totalChildrenMemo = useMemo(() => {
		return itemContentNode.reduce((total: number, item: any) => {
			const childrenCount = countChildrenRecursively(item, expanded);
			if (expanded.includes(item.key)) {
				return total + childrenCount;
			}
			return total;
		}, 0);
	}, [expanded]);

	const getHeight =
		itemContentNode.length < 17 && totalChildrenMemo < 10
			? itemContentNode.length === 1 &&
				itemContentNode[0].key === `EmptyContent`
				? "22vh"
				: `${(totalChildrenMemo + itemContentNode.length) * itemSizeinPX + 5}px`
			: "calc(80vh - 65px)";
	return (
		<Virtuoso
			components={{
				Item: MUIComponents.Item,
				Scroller: MUIComponents.Scroller as any,
			}}
			data={itemContentNode}
			increaseViewportBy={300}
			itemContent={itemContentBuild}
			initialTopMostItemIndex={initialTopItemIndex}
			style={{
				height: getHeight,
				width: "100%",
				margin: 0,
				paddingBottom: "1px",
			}}
		/>
	);
};
