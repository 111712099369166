import { isArray } from "lodash";

export type TreeNodeCustom = {
	key: string;
	props: {
		children?: TreeNodeCustom[];
		childrenNumber?: number;
		disabled?: boolean;
		label: string;
		nodeId: string;
		nodeType: string;
	};
};

export const getIndexforSelectedTree = (
	buildSite: JSX.Element,
	selectedTree: string,
) => {
	const arrayToAnalyzed = isArray(buildSite.props.children)
		? buildSite.props.children
		: ([] as TreeNodeCustom[]);
	const findHighestParentIndex = (
		nodeId: string,
		nodes: TreeNodeCustom[],
	): { index: number; parentCount: number; directIndex?: number } => {
		const findParent = (
			nodeId: string,
			nodes: TreeNodeCustom[],
			parentIndex: number | null,
			index: number,
			parentCount: number,
		): {
			index: number;
			parentCount: number;
			directIndex?: number;
		} | null => {
			if (index >= nodes.length) return null;

			const node = nodes[index];
			if (node.props.nodeId === nodeId) {
				if (parentIndex !== null) {
					return {
						index: parentIndex,
						parentCount,
						directIndex: index,
					};
				} else {
					return { index: index, parentCount };
				}
			}

			const foundInChildren = node.props.children
				? findParent(
						nodeId,
						node.props.children,
						parentIndex !== null ? parentIndex : index,
						0,
						parentCount + 1,
					)
				: null;

			return foundInChildren !== null
				? foundInChildren
				: findParent(nodeId, nodes, parentIndex, index + 1, parentCount);
		};

		const result = findParent(nodeId, nodes, null, 0, 0);
		return result !== null ? result : { index: -1, parentCount: 0 };
	};

	const keyInfo = findHighestParentIndex(selectedTree, arrayToAnalyzed);
	if (keyInfo.index === -1) return { index: 0, parentCount: 0 };
	return keyInfo;
};
