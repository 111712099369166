import type ColumnTable from "arquero/dist/types/table/column-table";
import type { Struct } from "arquero/dist/types/table/transformable";
import { isAfter, isSameDay } from "date-fns";
import { format } from "date-fns-tz";
import { meteringplanBenchmark } from "src/@types/installation";
import {
	Frequency,
	naiveStartOfPeriod,
	utcDateFromLocalTz,
} from "../timeFormatter";

export function addArqueroFunctions(aq: any) {
	if (aq.op.getWeek === undefined)
		aq.addFunction("getWeek", (date: string) =>
			format(
				naiveStartOfPeriod(utcDateFromLocalTz(date), Frequency.Week),
				"yyyy-MM-dd",
			),
		);
	if (aq.op.getMonth === undefined)
		aq.addFunction("getMonth", (date: string) => date.slice(0, 7));
	if (aq.op.getYear === undefined)
		aq.addFunction("getYear", (date: string) => date.slice(0, 4));

	if (aq.op.restrictPeriod === undefined)
		aq.addFunction(
			"restrictPeriod",
			(date: string | undefined, dateStart: string, dateEnd: string) => {
				if (date === undefined) return false;
				// date is in format 2024-01-01: string comparison should be enough
				return date >= dateStart && date <= dateEnd;
			},
		);

	if (aq.op.aggDistinctArray === undefined)
		aq.addAggregateFunction("aggDistinctArray", {
			create: () => ({
				// biome-ignore lint: arquero needs assignation in init
				init: (state: { array: string[] }) => (state.array = []),
				add: (state: { array: string[] }, value: string[]) => {
					const distinctSet = new Set([...state.array, ...value]);
					state.array = [...distinctSet];
				},
				rem: (state: { array: string[] }, value: string[]) =>
					// biome-ignore lint: arquero needs assignation in rem
					(state.array = state.array.reduce((acc, current, index) => {
						if (value.includes(current)) acc.splice(index, 1);
						return acc;
					}, state.array)),
				value: (state: { array: string[] }) => state.array,
			}),
			param: [1, 0],
		});
}

export function restrictTablePeriod(
	aq: any,
	data: ColumnTable,
	filterPeriod: [string, string],
) {
	const returnData = data.filter(
		aq.escape((d: Struct) =>
			aq.op.restrictPeriod(d.date, filterPeriod[0], filterPeriod[1]),
		),
	);
	return returnData.reify();
}

export function mergeTwoColumns(
	aq: any,
	data: ColumnTable,
	col1: string,
	col2: string,
) {
	const dataCols = data.columnNames();
	// must include col1 & col2
	if (!dataCols.includes(col1) || !dataCols.includes(col2)) return data;

	const newCol = {
		[col1]: aq.escape((d: Struct) => {
			if (
				d[col1] !== undefined &&
				!isNaN(d[col1]) &&
				d[col2] !== undefined &&
				!isNaN(d[col2])
			)
				return d[col1] + d[col2];
			else if (d[col1] !== undefined && !isNaN(d[col1])) return d[col1];
			else if (d[col2] !== undefined && !isNaN(d[col2])) return d[col2];
			else return undefined;
		}),
	};
	return data.derive(newCol).select(aq.not(col2)).reify();
}

export function getActiveSiteByDate(
	aq: any,
	dataTable: ColumnTable,
	installationTable: ColumnTable,
) {
	const allDates = dataTable.groupby("date").count().array("date");
	const activeSiteData = allDates.reduce(
		(
			data: {
				date: string[];
				active_installation_uuid: string[];
				active_site_count: number[];
			},
			dateAsString: string,
		) => {
			const date = new Date(dateAsString);
			const dateTable = installationTable
				.filter(
					aq.escape((d: Struct) => {
						return (
							(isAfter(date, d.date_begin_customer) ||
								isSameDay(date, d.date_begin_customer)) &&
							(d.date_end_customer === null ||
								isAfter(d.date_end_customer, date) ||
								isSameDay(date, d.date_end_customer))
						);
					}),
				)
				.reify();
			data.date.push(dateAsString);
			// active_installation_uuid will be used to compute the active_site_count when aggregating over period (Week, Month, ...)
			data.active_installation_uuid.push(dateTable.array("installation_uuid"));
			// active_site_count to date : the number of sites that is active at this date (begin < date < end)
			data.active_site_count.push(dateTable.numRows());
			return data;
		},
		{
			date: [],
			active_installation_uuid: [],
			active_site_count: [],
		} as {
			date: string[];
			active_installation_uuid: string[];
			active_site_count: number[];
		},
	);
	return aq.table(activeSiteData);
}
