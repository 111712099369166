import { Params, type Path, generatePath } from "react-router";
import { ChartTypesUrl, type ElectricityTypes } from "src/@types/charts";
import { PATH_DASHBOARD } from "src/routes/paths";

export function analysisUrl(
	chartType: ElectricityTypes,
	node: string,
	installation: string,
	searchParams?: URLSearchParams,
): Path | null {
	if (node === "" || installation === "") return null;

	const to: Path = {
		pathname: generatePath(
			`${PATH_DASHBOARD.analysis.root}/:chartPath/:node/:installation`,
			{ chartPath: ChartTypesUrl[chartType] || null, node, installation },
		),
		search: searchParams?.toString() || "",
		hash: "",
	};

	return to;
}

export function generateUrlIntern(
	location: Location,
	to: string,
	params: Params | undefined,
	search?: string,
) {
	const prefix = location.pathname.match(/^\/[^/]+/)?.[0] || "";
	const isSubfolder = ["/beta", "/preprod"].includes(prefix);

	const locationTo = isSubfolder ? prefix + to : to;

	let newurl = generatePath(locationTo, params);

	if (search) {
		const queryString = new URLSearchParams(search).toString();
		newurl = `${newurl}?${queryString}`;
	}

	return newurl;
}

export const isUUID = (segment: string) => {
	return /^[a-f\d]{8}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{4}-[a-f\d]{12}$/i.test(
		segment,
	);
};

export const goToWithPeriod = (
	pathRoot: string,
	customerId: string | undefined,
) => {
	return {
		pathname: generatePath(pathRoot, { customer: customerId }),
		search: `?period=12months`,
	};
};
